.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  text-align: center; 
}

.hero {
  background-color: #f0f5f5; /* Example background color */
  padding: 100px 0; 
}

.hero h1 {
  font-size: 3rem; 
  font-weight: 700;
  color: #333; 
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  background-color: #fff; 
  padding: 20px 0; 
  border-bottom: 1px solid #eee;  /* Add a subtle bottom border */
}

.container {
  max-width: 960px; 
  margin: 0 auto; 
  display: flex;
  justify-content: space-between; 
  align-items: center; 
}

.logo {
  height: 50px;  
}

.header nav ul {
  list-style: none; 
  margin: 0;
  padding: 0;
  display: flex; 
}

.header nav li {
  margin-left: 30px; 
}

.header nav a {
  text-decoration: none; 
  color: #333; 
  font-weight: 600; 
}